<template>
  <el-form
    :model="dataForm"
    ref="form"
    label-width="100px"
    class="login-form">
    <el-form-item 
      label="姓名"
      prop="name"
      required>
      <el-input
        disabled
        v-model="dataForm.name">
      </el-input>
    </el-form-item>
    <el-form-item
      label="性别"
      prop="sex"
      required>
      <el-input
        disabled
        v-model="dataForm.sex">
      </el-input>
    </el-form-item>
    <el-form-item
      label="身份证号"
      prop="citizenId"
      required>
      <el-input
        disabled
        v-model="dataForm.citizenId">
      </el-input>
    </el-form-item>
    <el-form-item
      label="证书类型"
      prop="certType"
      required>
        <el-select 
          v-model="dataForm.certType" 
          :disabled="!edit"
          placeholder="请选择">
          <el-option
            v-for="item in certTypeList"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item 
      label="专业"
      prop="major"
      required>
        <el-select 
          v-model="dataForm.major" 
          :disabled="!edit"
          placeholder="请选择">
          <el-option
            v-for="item in majorList"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item
      label="证书号"
      prop="certNo"
      required>
      <el-input
        :disabled="!edit"
        v-model="dataForm.certNo">
      </el-input>
    </el-form-item>
    <el-form-item
      label="证书2(双证)"
      prop="certNo2">
      <el-input
        :disabled="!edit"
        v-model="dataForm.certNo2">
      </el-input>
    </el-form-item>
    <el-form-item
      label="省市区"
      prop="ssq"
      required>
      <el-cascader
        v-model="dataForm.ssq"
        :disabled="!edit"
        :options="ssqData">
      </el-cascader>
    </el-form-item>
    <el-form-item
      label="详细地址"
      prop="address"
      required>
      <el-input
        type="textarea"
        :disabled="!edit"
        :rows="3"
        v-model="dataForm.address">
      </el-input>
    </el-form-item>
    <el-form-item
      label="邮箱"
      prop="email">
      <el-input
        :disabled="!edit"
        v-model="dataForm.email">
      </el-input>
    </el-form-item>
    <el-form-item
      label="手机号"
      prop="mobile"
      required>
      <el-input
        :disabled="!edit"
        v-model="dataForm.mobile">
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button :disabled="edit" @click="edit=true">修改</el-button>
      <el-button :disabled="!edit" type="primary" @click="submitForm()">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// import { toRaw } from '@vue/reactivity'
import ssqData from '@/common/ssqData2'
export default {
  data() {
    return {
      dataForm: {
        name: '',
        sex: '',
        citizenId: '',
        certType: '',
        major: '',
        certNo: '',
        certNo2: '',
        ssq: [],
        address: '',
        email: '',
        mobile: '',
      },
      certTypeList: ['执业药师', '从业药师'],
      majorList: ['药学', '中药学', '双证'],
      ssqData: ssqData,

      edit:false
    };
  },
  mounted: function () {
    this.$net.userInfo().then(({ data }) => {
      this.dataForm=data.user
      this.dataForm.ssq = [data.user.addressSheng,data.user.addressShi]
    });
  },
  methods: {
    submitForm () {
      const net = this.$net
      this.$refs['form'].validate((valid) => {
        if (valid) {
          net.userUpdate({
            "certType": this.dataForm.certType,
            "major": this.dataForm.major,
            "certNo": this.dataForm.certNo,
            "certNo2": this.dataForm.certNo2,
            "addressSheng": this.dataForm.ssq[0],
            "addressShi": this.dataForm.ssq[1],
            "address": this.dataForm.address,
            "email": this.dataForm.email,
            "mobile": this.dataForm.mobile
          }).then(()=>{
            this.$alert('保存成功')
            this.edit = false
          }).catch((res)=>{
            this.$alert(res.msg )
          })
        } 
      });
    }
  }
};
</script>
<style scoped>
.login-form{
    margin-top: 50px;
    width: 460px
}
</style>
